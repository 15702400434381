<template>
	<div class="land-delivery-detail">
		<div class="el-content delivery-order-detail mb12">
			<div class="delivery-od-number">单号：{{detail.order_number}}</div>
			<div class="delivery-od-opera">
				<div>
					<a-button
						size="link"
						v-has="{action:'wxapp_land_send_order_refund',plat:isShop}"
						@click="changeDeliveryOrderStatus('refund_yes',detail.id)"
						v-if="detail.is_refund !=2 && detail.is_cancel ==0 && detail.pay_status == 1 && detail.is_settle == 0 && detail.is_free_delivery==0">退款
					</a-button>
					<com-pay-result :id="detail.id" :type="10"></com-pay-result>
				</div>

				<div class="delivery-od-opera-status">
					<div class="lodos-row">
						<div>状态</div>
						<div>{{$status.getSendOrderStatus(detail)}}</div>
					</div>
					<div class="lodos-row">
						<div>订单金额</div>
						<div>￥{{detail.total_price}}</div>
					</div>
				</div>
			</div>

			<div class="delivery-od-cont">
				<div class="delivery-odc-item">
					<p>创建人：{{detail.name}}</p>
					<p>创建时间：{{detail.create_time}}</p>
					<p>送达日期：{{detail.pre_delivery_time}}</p>
				</div>

				<div class="delivery-odc-item">
					<p>收货姓名：{{detail.name}}</p>
					<p>收货电话：{{detail.phone}}</p>
					<p>收货地址：{{detail.address}}</p>

				</div>

				<div class="delivery-odc-item">
					<p v-if="detail.delivery_weight > 0 ">配送重量：{{detail.delivery_weight}} kg</p>
					<p v-else>配送重量：-</p>
					<p>备注:{{detail.remark}}</p>
				</div>
			</div>
		</div>

		<div class="el-content">
			<div style="display: flex;justify-content: space-between;">
				<span>配送进度</span>
				<a-space>
					<span
						v-has="{action:'wxapp_land_send_order_send',plat:isShop}"
						v-if="$status.checkSendOrderopera(detail,'send')">
						<com-deliver-goods
							btn-type="primary"
							:id="detail.id"
							:type="4"
							:is-shop="isShop"
							@success="()=>{getOrderDetail()}">
						</com-deliver-goods>
					</span>
					<a-button
						v-has="{action:'wxapp_land_send_order_confirm',plat:isShop}"
						v-if="detail.delivery_status == 1"
						@click="handleDeliveryOrder('confirm','确认将该配送信息状态修改为已收货吗?')">确认送达
					</a-button>
					<span v-has="{action:'wxapp_land_send_order_logistics',plat:isShop}" v-if="detail.delivery_status >=1">
						<com-logistics :order-type="4" :pk="detail.id" btn-type="primary" btn-text="查看物流"></com-logistics>
					</span>
				</a-space>

			</div>
			<div class="order-steps">
				<a-steps :current="active" status="finish" >
				    <a-step title="创建订单" :description="detail.create_time" />
				    <a-step title="开始配送" :description="detail.send_time" />
				    <a-step title="配送完成" :description="detail.confirm_time" />
				    <a-step title="确认签收" :description="detail.confirm_time" />
				</a-steps>
			</div>
		</div>
	</div>
</template>

<script>
import landModel from '@/api/land.js'
import comPayResult from '@/components/miniapp/com-pay-result.vue'
import comLogistics from '@/components/miniapp/com-logistics.vue'
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useLandDeliveryOrder} from '@/models/land'
export default{
	name:'page-delivery-detail',
	components:{
		comPayResult,
		comLogistics,
		comDeliverGoods
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			detail:{},
			active:0,
		})
		const opt = useRoute().query
		if( opt.id ) getOrderDetail()

        let { changeDeliveryOrderStatus } = useLandDeliveryOrder(null,getOrderDetail )

		function getOrderDetail(){
			landModel.getDeliveryOrderDetail(opt.id,res=>{
				_d.detail = res.order
				if( _d.detail.delivery_status ==2 ) _d.active = 4
				if( _d.detail.delivery_status ==1 ) _d.active = 1
			})
		}
		return{
			...toRefs(_d),
			getOrderDetail,
            changeDeliveryOrderStatus
		}
	},
}
</script>

<style lang="scss">
	.land-delivery-detail{
		.order-steps{
			margin-top: 32px;
			width: 80%;
			margin-left: 10%;
		}
	}
	.delivery-order-detail{
		.delivery-od-number{
			font-size: 18px;
			font-weight: bold;
		}
		.delivery-od-cont{
			display: flex;
			margin-top: 16px;
			margin-bottom: 16px;

			.delivery-odc-item{
				width: 320px;
				font-size: 14px;
				line-height: 30px;
				color: #333;
				p{
					margin: 0;
				}
			}
		}
		.delivery-od-opera{
			position: absolute;
			right: 3%;
			margin-top: -30px;
			text-align: right;

			&-status{
				display: flex;
				justify-content: flex-end;
				.lodos-row{
					text-align: right;
					margin-top: 16px;
					margin-left: 24px;

					div:first-child{
						color: #999;
						font-size: 14px;
					}

					div:last-child{
						color: #333;
						font-size: 18px;
						margin-top: 8px;
					}
				}
			}
		}
	}
</style>
